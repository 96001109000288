export const environment = {
  production: true,
  appInsights: {
    instrumentationKey: 'd3cd9068-243a-4e4a-a0aa-36e18fc58334'
  },
  staticDataUrl: 'https://fincenboireportingprod.blob.core.windows.net/public/static-data',
  apiUrl: 'https://fincenboireporting-prod-app.azurewebsites.net/api',
  calendyUrl: 'https://calendly.com/boi-reporting',
  googleMapApiKey: '',
  auth0: {
    domain: 'login.fincenboireporting.com',
    //domain: 'prod-fincenboireporting.us.auth0.com',
    clientId: 'V4NKjeeMKjPD6OUfi3nxl3E7LTk1gJ7R',
    audience: 'https://fincenboireporting-api.com'
  },
  stripe: {
    publicApiKey: 'pk_live_51OOiYDBnixkrH5b8FeiM0dnLVzvHKyvgwADWRqACSxR1V8nGjt4NXt0KtqYyj2PXGwbgmmNT00JmqNm2JTMT3hIK00NJ3mkRCx'
  }
};


